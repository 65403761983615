<template>
    <div id="access">
        <h2 is="sui-header" class="dividing">Access Management</h2>
        <sui-grid :columns="2">
            <sui-grid-row>
                <sui-grid-column>

                </sui-grid-column>
                <sui-grid-column text-align="right">
                    <sui-button-group size="small" v-if="displayAddUser">
                        <sui-button color="green" icon="user add" @click="addUser">Add User</sui-button>
                    </sui-button-group>
                </sui-grid-column>
            </sui-grid-row>
        </sui-grid>
        <sui-table celled selectable compact="">
            <sui-table-header full-width>
                <sui-table-row>
                    <sui-table-header-cell>
                        First Name
                    </sui-table-header-cell>
                    <sui-table-header-cell>
                        Last Name
                    </sui-table-header-cell>
                    <sui-table-header-cell>
                        Email
                    </sui-table-header-cell>
                    <sui-table-header-cell>
                        Status
                    </sui-table-header-cell>
                    <sui-table-header-cell>
                        Roles
                    </sui-table-header-cell>
                </sui-table-row>
                <sui-table-row>
                    <sui-table-header-cell>
                        <sui-input placeholder="Search..." icon="filter" size="small" transparent
                                   v-model="filterFirstName" @input="debouncedReloadFirstPage"/>
                    </sui-table-header-cell>
                    <sui-table-header-cell>
                        <sui-input placeholder="Search..." icon="filter" size="small" transparent
                                   v-model="filterLastName" @input="debouncedReloadFirstPage"/>
                    </sui-table-header-cell>
                    <sui-table-header-cell>
                        <sui-input placeholder="Search..." icon="filter" size="small" transparent v-model="filterEmail"
                                   @input="debouncedReloadFirstPage"/>
                    </sui-table-header-cell>
                    <sui-table-header-cell>
                        <sui-dropdown item placeholder="" :options="optionsStatus" v-model="filterStatus"
                                      @input="debouncedReloadFirstPage"/>
                    </sui-table-header-cell>
                    <sui-table-header-cell>
                        <sui-dropdown item placeholder="" :options="optionsRole" v-model="filterRole"
                                      @input="debouncedReloadFirstPage"/>
                    </sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <sui-table-body>
                <AccessRow v-for="u in users" :key="u.email" :user="u" @update:selectedRow="selectedRow"
                           @update:unselectedRow="unselectedRow"></AccessRow>
            </sui-table-body>
            <sui-table-footer>
                <sui-table-row>
                    <Pager :selected-page="selectedPage" :total-item-count="totalItemCount"
                           @update:selectedPage="loadPage"></Pager>
                </sui-table-row>
            </sui-table-footer>
        </sui-table>
        <sui-divider clearing></sui-divider>

        <sui-grid :columns="1">
            <sui-grid-row>
                <sui-grid-column>
                    <access-display v-for="id in rowSelectedArray" :key="id" :user="rowSelected[id]"
                                    @deactivate="deactivate" @activate="activate"></access-display>
                </sui-grid-column>
            </sui-grid-row>
        </sui-grid>


        <sui-divider clearing hidden></sui-divider>


    </div>
</template>

<script>
    import axios from "axios";
    import _ from 'lodash';
    import AccessRow from "@/views/dashboard/access/AccessRow";
    import Pager from "@/components/Pager";
    import AccessDisplay from "@/views/dashboard/access/AccessDisplay";

    export default {
        name: "Access",
        components: {AccessDisplay, AccessRow, Pager},
        data: function () {
            const status_options = ['All', 'Inactive', 'Active', 'Suspended'];
            const role_options = ['All', 'Property Manager', 'Administrative Staff', 'Building Manager', 'Security Guard', 'Security Installer'];

            return {
                filterFirstName: "",
                filterLastName: "",
                filterEmail: "",
                filterStatus: "",
                optionsStatus: status_options.map((i) => {
                    return {
                        text: i,
                        value: i === "All" ? "" : i
                    }
                }),
                filterRole: "",
                optionsRole: role_options.map((i) => {
                    return {
                        text: i,
                        value: i === "All" ? "" : i.split(" ").reduce((a, v) => {
                            return a + v[0]
                        }, "")
                    }
                }),


                users: [],

                totalItemCount: 0,
                selectedPage: 0,
                itemPerPage: 10,

                rowSelected: {},
                rowSelectedArray: [],
                displayAddUser: false,
            }
        },
        created: async function () {
            this.debouncedReloadFirstPage = _.debounce(this.reloadFirstPage, 250);
            await this.getUserRole();
            await this.loadPage({
                limit: this.itemPerPage,
                offset: 0,
            })
        },
        methods: {
            getUserRole: async function() {
                const role = await this.$config.userRole();
                if (role === "AS" ||role === "PM" ||role === "BM") {
                    this.displayAddUser = true;
                }
            },
            addUser: function () {
                this.$router.push('/dashboard/access/add');
            },
            reloadFirstPage: async function () {
                await this.loadPage({
                    limit: this.itemPerPage,
                    offset: 0,
                })
            },
            selectedRow: async function (row) {
                this.rowSelected[row.id] = row;
                this.rowSelectedArray = Object.keys(this.rowSelected);

            },
            unselectedRow: async function (row) {
                delete this.rowSelected[row.id];
                this.rowSelectedArray = Object.keys(this.rowSelected);
            },
            activate: async function (uid) {
                axios({
                    method: "POST",
                    url: "/api/user/activate",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    data: {
                        token: this.$cookies.get('token'),
                        tokenId: this.$cookies.get('tokenId'),

                        uid: "10"
                    }
                }).then(() => {
                    this.rowSelected[uid].status = "Active";
                })
            },
            deactivate: async function (uid) {
                axios({
                    method: "POST",
                    url: "/api/user/deactivate",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    data: {
                        token: this.$cookies.get('token'),
                        tokenId: this.$cookies.get('tokenId'),

                        uid: "10"
                    }
                }).then(() => {
                    this.rowSelected[uid].status = "Inactive";
                })
            },
            loadPage: async function (v) {
                try {
                    let result = await axios({
                        method: "POST",
                        url: "/api/user/list",
                        headers: {
                            "Content-Type": "application/json; charset=utf-8",
                        },
                        data: {
                            token: this.$cookies.get('token'),
                            tokenId: this.$cookies.get('tokenId'),

                            limit: v.limit,
                            offset: v.offset,

                            firstName: this.filterFirstName,
                            lastName: this.filterLastName,
                            email: this.filterEmail,
                            status: this.filterStatus,
                            role: this.filterRole,
                        }
                    });
                    const {rows, count} = result.data;
                    console.log(rows);
                    this.users = rows.map((i) => {
                        i.selected = this.rowSelected[i.id] !== undefined;
                        return i;
                    })
                    this.totalItemCount = count;
                } catch (e) {
                    await this.$router.push('/login');
                    console.log(e);
                }
            }
        }
    }
</script>

<style scoped>
    tr:nth-child(2) th {
        padding: 5px 0;
    }

    tr:nth-child(2) th .ui.input {
        width: 100%;
    }

</style>
