<template>
    <sui-segments horizontal>
        <sui-segment>
            <p><b>First Name:</b> {{firstName}}</p>
            <p><b>Last Name:</b> {{lastName}}</p>
            <p><b>Email:</b> {{email}}</p>
            <p>
                <b>Contact Number:</b><br> {{contactNumber}}
                <br v-if="alternateContactNumber"> {{alternateContactNumber}}
            </p>
        </sui-segment>
        <sui-segment>

            <p><b>Associated Roles:</b> {{role}}</p>
            <p>
                <b>Location:</b><br>
                {{street}}
                <br v-if="city || state || country">
                <span class="addr" v-if="city">{{city}}</span>
                <span class="addr" v-if="state">{{state}}</span>
                <span class="addr" v-if="country">{{country}}</span>
                <br v-if="zipCode">
                <span v-if="zipCode">{{zipCode}}</span>
            </p>
            <p class="btn-group-right">
                <sui-button-group size="mini">
                    <sui-button basic color="red" icon="user remove" v-if="status === 'Active'" @click.prevent="$emit('deactivate', user.id)">Deactivate</sui-button>
                    <sui-button basic color="green" icon="user add" v-if="status !== 'Active'" @click.prevent="$emit('activate', user.id)">Activate</sui-button>
                    <sui-button basic color="blue" icon="edit" @click="$router.push(`/dashboard/access/edit/${user.id}`)">Edit</sui-button>
                </sui-button-group>
            </p>
        </sui-segment>
    </sui-segments>
</template>

<script>
    export default {
        name: "AccessDisplay",
        props: {
            user: {
                email: String,
                firstName: String,
                lastName: String,
                id: Number,
                metadata: Object,
                role: String,
                status: String,
            }
        },
        computed: {
            role: function () {
                let m = {
                    "": "",
                    "PM": 'Property Manager',
                    "AS": 'Administrative Staff',
                    "BM": 'Building Manager',
                    "SG": 'Security Guard',
                    "SI": 'Security Installer',
                };
                return m[this.user.role];
            },
            status: function () {
                return this.user.status;
            },
            firstName: function () {
                return this.user.firstName;
            },
            lastName: function () {
                return this.user.lastName;
            },
            email: function () {
                return this.user.email;
            },
            contactNumber: function () {
                if (this.user.metadata) {
                    return this.user.metadata.contactNumber;
                }else{
                    return ""
                }
            },
            alternateContactNumber: function () {
                if (this.user.metadata) {
                    return this.user.metadata.alternateContactNumber;
                }else{
                    return "";
                }
            },
            street: function () {
                if (this.user.metadata) {
                    return this.user.metadata.street;
                }else{
                    return "";
                }
            },
            city: function () {
                if (this.user.metadata) {
                    return this.user.metadata.city;
                }else{
                    return "";
                }
            },
            state: function () {
                if (this.user.metadata) {
                    return this.user.metadata.state;
                }else{
                    return "";
                }
            },
            country: function () {
                if (this.user.metadata) {
                    return this.user.metadata.country;
                }else{
                    return "";
                }
            },
            zipCode: function () {
                if (this.user.metadata) {
                    return this.user.metadata.zipCode;
                }else{
                    return "";
                }
            },
        }
    }
</script>

<style scoped>

    span.addr:after {
        content: ", ";
    }

    .btn-group-right {
        float: right;
    }

    p.btn-group-right {
        position: absolute;
        right: 5px;
        bottom: 5px;
    }

    p b {
        display: inline-block;
        min-width: 80px;
    }

    .ui.segment{
        width: 50%;
    }
</style>