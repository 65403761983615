<template>
    <sui-table-row @mousedown="selectRow" :selected="user.selected">
        <sui-table-cell>
            {{user.firstName}}
        </sui-table-cell>
        <sui-table-cell>
            {{user.lastName}}
        </sui-table-cell>
        <sui-table-cell>
            {{user.email}}
        </sui-table-cell>
        <sui-table-cell>
            {{user.status}}
        </sui-table-cell>
        <sui-table-cell>
            {{user.role}}
        </sui-table-cell>
    </sui-table-row>
</template>

<script>
    export default {
        name: "AccessRow",
        props: {
            user: {
                email: String,
                firstName: String,
                lastName: String,
                role: String,
                status: String,
                selected: Boolean
            }
        },
        methods: {
            selectRow: function () {
                this.user.selected = !this.user.selected
                if (this.user.selected) {
                    this.$emit("update:selectedRow", this.user);
                }else{
                    this.$emit("update:unselectedRow", this.user);
                }
            }
        }
    }
</script>

<style scoped>

</style>